import styled from 'styled-components';

const CookieSettingsContainer = styled.div`
  .react-cookie-law-dialog {
    //background-color: #ffd15ced !important;
    border-radius: 8px;
    bottom:0px !important;
    top: auto !important;
    color: ${props => props.theme.colors.primaryDark} !important;
    position: inherit !important;

  }

  .react-cookie-law-msg {
    color: ${props => props.theme.colors.primaryDark} !important;
    font-size: 16px !important;
  }  
 
  .react-cookie-law-decline-btn{
      background-color: transparent !important;
      border: none !important;
      color: ${props => props.theme.colors.primaryDark} !important;
      padding: 15px !important;
      text-align: center !important;
      text-decoration: none !important;
      display: inline-block !important;
      font-size: 16px !important;
      margin: 4px 2px !important ;
      border-radius: 6px;

      span {
        text-decoration: underline;
      }
  }

  .react-cookie-law-accept-btn{
    background-color: ${props => props.theme.colors.primaryDark} !important;
    border: none !important;
    color: white !important;
    padding: 15px !important;
    text-align: center !important;
    text-decoration: none !important;
    display: inline-block !important;
    font-size: 16px !important;
    margin: 4px 2px !important ;
    border-radius: 6px;
}

  .react-cookie-law-option-wrapper{
        display: flex !important;
        align-items: center !important;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;      
        span {
            color: ${props => props.theme.colors.primaryDark} !important;
            font-family: 'Barlow Condensed' !important;
            font-weight: 700;
            position: relative !important;
            cursor: pointer !important;
            font-size: 0.9em !important;
            padding: 0 0.25em 0 !important;
            user-select: none;
            &::before {
              position: absolute;
              content: attr(data-content);
              color: ${props => props.theme.colors.primaryDark} !important;
              clip-path: polygon(0 0, 0 0, 0% 100%, 0 100%);
              text-decoration: line-through;
              text-decoration-thickness: 3px;
              text-decoration-color: ${props => props.theme.colors.primaryDark} !important;
              transition: clip-path 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
            }            

        }

        input[type="checkbox"] {
            position: relative !important;
            width: 1.5em !important;
            height: 1.5em !important;
            color: ${props => props.theme.colors.primaryDark} !important;
            border: 1px solid ${props => props.theme.colors.primaryDark} !important;
            border-radius: 4px;
            appearance: none !important;
            outline: 0;
            cursor: pointer !important;
            transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1) !important;
            &::before {
              position: absolute;
              content: '';
              display: block;
              top: 2px;
              left: 7px;
              width: 8px;
              height: 14px;
              border-style: solid;
              border-color: ${props => props.theme.colors.primary} !important;
              border-width: 0 2px 2px 0;
              transform: rotate(0deg) !important;
              opacity: 0 !important;
            }
            &:checked {
              color: ${props => props.theme.colors.white} !important;
              border-color: ${props => props.theme.colors.primaryDark} !important;
              background: ${props => props.theme.colors.primaryDark} !important;
              &::before {
                position: absolute;
                content: "\f00c";
                font: normal normal normal 14px/1 icomoon;
                color: ${props => props.theme.colors.white} !important;
                //right: 40%;
                color: #000;
                bottom: 0;
                height: 30px !important;
                width: 30px !important;
                z-index: 1;
                opacity: 1 !important;
                border-width: 0px !important;
                font-size: 20pt;
                top: 3px !important;
                left: 4px !important;
              }
              ~ span::before {
                clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
              }
            }
            &:disabled {
                color: #FFFFFF;
                border-color: ${props => props.theme.colors.disabled} !important;
                background: ${props => props.theme.colors.disabled} !important;   
            }
          }
  }


  @media only screen and (max-width: 912px) {

  }
`

export default CookieSettingsContainer;