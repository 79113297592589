import React, { useState, useEffect, Fragment } from 'react';
import tw from 'twin.macro';
import Fade from 'react-reveal/Fade';
import CookieSettingsContainer from './cookie-settings.style';
import { CookieBanner } from '@palmabit/react-cookie-law';
import { Cookies } from 'react-cookie-consent';
import firebase from 'firebase';
import { useFirebase } from '@hooks/UseFirebase';

interface CookieSettingsProps {
  onSelectCookie:any
}

const CookieSettings = (props:CookieSettingsProps) => {

  //const [acceptClicked, setAcceptClicked] = React.useState(false);
  //const [declineClicked, setDeclineClicked] = React.useState(false);
  const date = new Date();
  date.setTime(date.getTime()+(365*24*60*60*1000));

  let acceptClicked = false;
  let declineClicked = false;

  useEffect(() => {
      var checkStatistics = document.getElementById('check-statistics-cookies');
      //console.log('CookieSetting useEffect');
      let acceptClicked = false;
      let declineClicked = false;
  //    setAcceptClicked(false);
  //    setDeclineClicked(false);
      
      if (checkStatistics){
        checkStatistics['checked'] = false;
        checkStatistics.click(); //['checked'] = false;
      }

      const cookieConsent:boolean = Cookies.get('rcl_consent_given');
      if (cookieConsent){
        props.onSelectCookie();
      }
  }, []) // this diff is necessary

  const accept = () => {
          //-- hacemos que se oculte el banner de cookies
          //console.log('onAccept --- init');
          if (acceptClicked){
            return;
          }
          
          //console.log('onAccept ---');
          acceptClicked = true;
          props.onSelectCookie();
          //Cookies.set('chamartincocinaparati-cookiebanner',true, {expires: date.getTime()});
          // props.onClose();
  }

  const decline = ()=> {
    //console.log('onDeclineStatistics -- init ');
    
    if (declineClicked){
      return;
    }

    //console.log('onDeclineStatistics --');
    declineClicked = true;
    props.onSelectCookie();
    
    //-- hacemos que se oculte el banner de cookies
    //Cookies.set('chamartincocinaparati-cookiebanner',false, {expires: date.getTime()});
    //props.onClose();
    //-- cerrar ventana modal
  }
  return(
  <CookieSettingsContainer>
        <CookieBanner
        message="Cocina para ti utiliza cookies propias y de terceros para mejorar tu experiencia en nuestra web así como para analizar el tráfico web de la misma. Para obtener más información sobre las cookies que utilizamos, consulta nuestra Política de privacidad."   
        privacyPolicyLinkText="Política de cookies"
        policyLink="/politica_de_cookies"
        necessaryOptionText="Necesarias"
        statisticsOptionText="Estadísticas"
        showPreferencesOption={false}
        showMarketingOption={false}
        showDeclineButton={true}
        declineButtonText="Rechazar"
        acceptButtonText="Aceptar"
        className="cookie-banner-consent"
        onAccept = {accept}
        onAcceptStatistics = {() => {

          //console.log('onAcceptStatistics --');
          Cookies.set('chamartincocinaparati-gdpr-google-analytics',true, {expires: date.getTime()});
          Cookies.set('chamartincocinaparati-gdpr-google-tagmanager',true,  {expires: date.getTime()});
          Cookies.set('chamartincocinaparati-gdpr-facebook-pixel',true,  {expires: date.getTime()});        
          
          const firebaseApp = useFirebase();
          //  console.log('analytics apoyo local', firebaseApp);
          //  console.log('cookies', analytics);
            if (firebaseApp){
          //    console.log('analytics apoyo local -- habilitando analytics');
              firebase.analytics();
            }            
        }}

        //onDeclineStatistics = {decline}
        />    
  </CookieSettingsContainer>
  );
}

export default CookieSettings;




