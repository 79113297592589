import React, { useState, useEffect, Fragment } from 'react';
import { Link, navigate } from 'gatsby';
import tw from 'twin.macro';

import styled from 'styled-components';
import { FaBars, FaDesktop } from "react-icons/fa";
import { Flex, Image } from 'rebass/styled-components';
import { SectionLink } from 'react-scroll-section';
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Fade from 'react-reveal/Fade';
import RouteLink from '../RouteLink';
import Logo from '@components/Logo/logoWhite.svg';
import LogoColor from '@components/Logo/logoColor.svg';
import ScrollspyMenu from './utility/ScrollspyMenu';
import HeaderContainer from "./header.style";
import LinkAnimated from '@components/LinkAnimated';
import Button from '@components/common/Button';

/*
https://kyleamathews.github.io/react-headroom/
***************************************************************

React Headroom is a React Component to hide/show your header on scroll. 
The header on this site is a living example. 
When you scroll down, it slides out of view and slides back in when scrolling up.

Fixed headers are nice for persistent navigation but they can also get in the way by taking up valuable vertical screen space. 
Using this component lets you have your persistent navigation while preserving screen space when the navigation is not needed.


https://www.digitalocean.com/community/tutorials/how-to-implement-smooth-scrolling-in-react
******************************************************************************************************************************
What is smooth scrolling? Instead of clicking on a button and being instantly taken to a different part of the (same) page, the user is navigated there via a scroll animation. 
It’s one of those subtle features on a site that makes an aesthetic difference.

In this article, we are going to use the react-scroll package on NPM to implement smooth scrolling.


https://github.com/EmaSuriano/react-scroll-section
***************************************************************

alllinks from <sectionLink>

*/

const capitalize = (s:string) => s && s[0].toUpperCase() + s.slice(1);

const CardContainer = styled.div`
  display: grid;
  grid-gap: 30px;

  grid-template-columns: repeat(
    auto-fill,
    minmax(${(props) => props.minWidth}, 1fr)
  );
  justify-items: center;

  @media only screen and (max-width: 400px) {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
`;

const ProgressContainer=styled.div`
  background:linear-gradient(to right, #4dc0b5 var(--scroll), transparent 0);
`;

const HeaderNav = tw.nav`fixed w-full z-10 top-0`;
const NavContainer= tw.div`w-full md:max-w-6xl mx-auto flex flex-wrap items-center justify-between mt-1 lg:mt-0 py-3`;
const NavFullContainer= tw.div`w-full mx-auto flex flex-wrap items-center justify-between mt-1 lg:mt-0 py-3`;
const HeaderNavContainer = tw.div`flex-initial justify-between items-center flex-wrap p-0 w-full`;
const LogoContainer = tw.div`flex justify-start items-end`;

//         <h2>{" "}<span> Chamartín </span></h2>
const formatLinks = (allLinks) =>
  Object.entries(allLinks).reduce(
    (acc, [key, value]) => {
      const isHome = key === 'home';
      return isHome
        ? {
            ...acc,
            home: value,
          }
        : {
            ...acc,
            links: [...acc.links, { name: capitalize(key), value }],
          };
    },
    { links: [], home: null },
  );

interface Props {
  fullWidth?: boolean;
}

const Header = ({ fullWidth }: Props) => {

  const [expand, setExpand] = useState(false);
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () =>
      window.pageYOffset > 50 ? setScroll(true) : setScroll(false)
    );
  }, [ ]);
  
  const homeLink = (
    <LogoContainer>
      <Link to="/" className="logo">              
        <Image
          src={LogoColor}
          width="50px"
          alt="Cocina para ti"
          style={{
            cursor: 'pointer',
          }}
        />
      </Link>
      
      <div className="section-title">

        <h1>
          <span>Cocina para ti</span>
        </h1>
      </div>
      
    </LogoContainer>
  );
    
  const navLinks = (
    <ul className={`collapsed ${expand ? "is-expanded" : ""}`}>
      { /*
      <li
        key="features"
        className="nav-item"
        name="Características"
      >
        <AnchorLink 
            to="/#features" 
            title="Características"
            stripHash
            >                          
              <LinkAnimated
                 tabIndex={0}> Características
              </LinkAnimated>
            </AnchorLink>

      </li>
      */}
    { /*
      <li
        key="testimonials"
        className="nav-item"
        name="Testimonios"
      >
        <AnchorLink 
            to="/#testimonials" 
            title="Testimonios"
            stripHash
            >                           
          <LinkAnimated  tabIndex={1}> Testimonios
          </LinkAnimated>
        </AnchorLink>
      </li>        
    */ }

      <li
        key="establecimientos"
        className="nav-item"
        name="establecimientos"
      >                            
          <LinkAnimated tabIndex={0} onClick={() => {navigate("/mapa");}}> 
            Establecimientos
          </LinkAnimated>
      </li>  

      <li
        key="promociones"
        className="nav-item"
        name="promociones"
      >                            
          <LinkAnimated tabIndex={1} onClick={() => {navigate("/promociones");}}> 
            Promociones
          </LinkAnimated>

      </li>          

      <li
        key="blog"
        className="nav-item"
        name="blog"
      >                            
          <LinkAnimated tabIndex={2} onClick={() => {navigate("/blog");}}> 
            Blog
          </LinkAnimated>

      </li>

      <li
        key="sello"
        className="nav-item"
        name="sello"
      >                            
          <LinkAnimated tabIndex={3} onClick={() => {navigate("/sello_solidario");}}> 
            Sello solidario
          </LinkAnimated>
      </li>            

      <li
        key="signup"
        className="nav-item"
        name="Apúntate"
      >
        <SectionLink section="signup">
          {({ onClick, isSelected }) => (                            
            <Button className="signup-btn" onClick={onClick}>
                <FaDesktop className="icon-left" />
                Apúntate
            </Button>
          )}
        </SectionLink>
      </li>                          
    </ul>
  )


  const renderHeader = (
    <HeaderNavContainer>
      <div className="navbar-wrap">
        {homeLink}
              
        <nav className={`nav`}>
          <FaBars
                className="mobile-menu-icon"
                onClick={() => setExpand(!expand)}
          />                  
          <Flex mr={[0, 3, 5]}>
            {navLinks}
          </Flex>
        </nav>
      </div>
    </HeaderNavContainer>
  );


  return(
  <HeaderNav id="header" className={`nav-block ${scroll ? "scrolled" : ""}`}>
    
  <HeaderContainer className={`nav-block ${scroll ? "scrolled" : ""} ${expand ? "nav-is-expanded" : ""}`}>
  
  {fullWidth === true &&
  <NavFullContainer>
  {renderHeader} 
  </NavFullContainer> 
  }

  {(fullWidth === undefined || fullWidth === false) &&
  <NavContainer>
  {renderHeader}
  </NavContainer>
  }

  </HeaderContainer>
  </HeaderNav>
  );
}

export default Header;
