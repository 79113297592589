import React, { useState, useEffect } from 'react';
import { Modal as ReactModal }  from 'react-bootstrap';

import ModalContainer from './cookie-consent.style';
import CookieSettings from '@components/Cookies/CookieSettings';

interface ModalProps {
    handleClose:any,
    handleShow: any,
    title: string,
    show: boolean
}



const CookieConsentModal = (props) => {

    //const [showConsent, setShowConsent] = useState(false);
    //const [closeConsent, setCloseConsent] = useState(false);

    //const handleClose = () => setShow(false);
    //const handleShow = () => setShow(true)

    let showConsent = true;

    const handleClose = () => {
        //console.log('CookieConsentModal -- handleClose');
        showConsent = true;
        //setShowConsent(false);
        //props.onHide();
        props.onSelect();
        //setShowConsent(false);
        //props.onSelect();
        //props.handleClose();
    }
    
  return (
    <ModalContainer>
        {showConsent &&
        <ReactModal onHide={props.onHide} {...props} centered scrollable dialogClassName="modal-cookie-consent">
        <ReactModal.Header closeButton>
            <ReactModal.Title>{props.title}</ReactModal.Title>
        </ReactModal.Header>

        <ReactModal.Body style={{'maxHeight': 'calc(100vh - 210px)', 'overflowY': 'auto'}}>
            <CookieSettings onSelectCookie={handleClose}/>
        </ReactModal.Body>
        </ReactModal>
        }
    </ModalContainer>
  );
}

export default CookieConsentModal;