import styled from 'styled-components';

const HeaderContainer = styled.header`
  position: absolute;
  width: 100%;
  background: transparent;
  position: fixed;
  width: 100%;
  z-index: 48;
  top: 0;
  transition: all 0.3s ease 0s;
  &.nav-is-expanded {
    background-color: white;
    border-radius: 5px;
  }
  .navbar-wrap{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .mobile-menu-icon {
    display: none;
    color: ${props => props.theme.colors.primaryDark};
    font-size: 24px;
  }

  .section-title {
    padding-left:10px;
    h2 {
      font-size: 80%;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      margin: 0px !important;
      span {
        background: ${props => props.theme.colors.backgroundDark};
        padding: 7px 13px;
        color: ${props => props.theme.colors.white};
        border-radius: 25px;
        margin-right: 7px;
        line-height: 100%;
        font-size: 14pt;
      }
    }
    h1 {
      font-weight: 300;
      font-size: 40px;
      line-height: 40px;
      margin: 0 0 0px 0;
      span{
        font-weight: 800;
      }
    }
  }

  /*Drop down style*/ 
  .dropdown{
    background: #fff;
    visibility: hidden;
    opacity: 0;
    min-width: 200px;
    border: 1px solid #f5f5f5;
    position: absolute;
    margin-top: 1rem;
    left: 20px;
    padding: 15px 0px;
    display: none;
    box-shadow: 0px 30px 70px 0px rgba(137,139,142,0.15);
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
    transition: all 0.3s ease-in-out;
  }
  ul li:hover > ul,
  ul li ul:hover {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
    display: block;
  }
  
  nav {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: center;

      &.is-expanded {
        padding-left: 40px;
        padding-right: 40px;

        li {
          padding-bottom: 10px !important;
        }
      }
    }
    li{
        position: relative;
        padding: 20px 0px 20px 40px;
        color: ${props => props.theme.colors.primaryDark};

        .dropdown{
          li{
            padding: 0;
            a{
              color: #1D316C;
              display: block;
              padding: 5px 15px;
              &:hover{
                color: ${props => props.theme.colors.menuHoverColor ? props.theme.colors.menuHoverColor : '#FB7B81'} !important;
              }
              &:before{
                content: none;
              }
              span {
                color: #1D316C;
                display: block;
                padding: 5px 15px;
                &:hover{
                  color: ${props => props.theme.colors.menuHoverColor ? props.theme.colors.menuHoverColor : '#FB7B81'} !important;
                  cursor:pointer;
                }
                &:before{
                  content: none;
                }
              }                
            }
          
          }
        }
      
      a, .nav-active{
        position: relative;
        color: ${props => props.theme.colors.menuColor ? props.theme.colors.menuColor : '#1D316C'};
        transition: 450ms all;
        padding-bottom: 5px;
        cursor: pointer;
        /*
        &:before{
          content: '';
          position: absolute;
          left : 0;
          bottom:0;
          width: 20px;
          height: 1px;
          opacity: 0;
          background: ${props => props.theme.colors.menuHoverColor ? props.theme.colors.menuHoverColor : '#FB7B81'};
          transition: 450ms all;
        }
        */
        &:hover{
          color: ${props => props.theme.colors.menuHoverColor ? props.theme.colors.menuHoverColor : '#FB7B81'};
          text-decoration: none;
          cursor: pointer;
          &:before{
            opacity: 1;
          }
        }
      }
    }
  }
  .logo{
    margin-top: 8px;
    margin-left: 30px;
    transition: all 0.8s cubic-bezier(0.3, 0.8, 0.2, 1) 0s;
    img{
      width: 60px;
    }
  }
  &.scrolled{
    box-shadow: rgba(0, 0, 0, 0.05) 0px 10px 30px;
    background: ${(props) => props.theme.colors.white};
    .logo{
        width: 60px;
    } 
    .section-title {
      .hh2 {
        span {
          background: ${props => props.theme.colors.primaryDark};
          color: ${props => props.theme.colors.white};
        }
      }
      .hh1 {
        span{
          color: ${props => props.theme.colors.white};
        }
      }
    }    
    .nav{
      li{
        padding: 28px 0px 28px 40px;
        a{
          color: ${props => props.theme.colors.primaryDark};
          &:hover{
            color: ${props => props.theme.colors.menuHoverColor ? props.theme.colors.menuHoverColor : '#FB7B81'};
          }
          span{
            color:  ${props => props.theme.colors.primaryDark};
            &:hover{
              color: ${props => props.theme.colors.menuHoverColor ? props.theme.colors.menuHoverColor : '#FB7B81'};
            }
          }          
        }

      }
      li{
        .dropdown{
          li{
            padding: 0;
          }
        }
      }
    }
  }

  .signup-btn {
    background: ${props => props.theme.colors.primaryButton};
    color: ${props => props.theme.colors.white};
    font-weight: 800;
    font-family: 'Poppins';
    z-index:9;
    &:hover {
      background: ${props => props.theme.colors.primaryLight};
      color: ${props => props.theme.colors.primaryDark};
    }
  }    

  @media only screen and (max-width: 912px) {
    height: auto;
    min-height: 50px;
    padding: 0px 20px;
    background: transparent;  
    .section-title {
      padding-left:10px;
      h2 {
        font-size: 60%;
      }
      h1 {
        font-weight: 300;
        font-size: 25px;
        line-height: 25px;
        span{
          font-weight: 700;
        }
      }
    }    
    .logo {
      display: block;
      margin: 7px 0 0 0;
      img{width: 30px;}
    }    
    &.scrolled{
        .logo {
          width: 30px;
        }      
        .nav .collapsed {
          li{
            padding: 0;
            a{
              padding: 8px 0px;
              display: block;
            }
          }
          li+li{
            padding-left: 0;
          }
        } 
    }
    .has-dropdown{
      a{
        position: relative;
        &:before{
          content: '';
          
        }
      }
    }
    .dropdown{
      position: relative;
      left: 0;
      margin-top: 0;
      box-shadow: none;
    }
    .navbar-wrap{
        display: block;
        position: relative;
    }
    .mobile-menu-icon {
      display: inline-block;
      position: absolute;
      top: 8px;
      right: 3px;
      cursor: pointer;
    }
    .nav{
        width: 100%;
      .collapsed {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        transition: 450ms all ease-in-out;
        overflow: hidden;
        max-height: 0;

        &.is-expanded {
          padding-top: 15px;
          overflow-y: auto;
          max-height: 250px; /* approximate max height */
        }
        li {
          padding: 0;
          margin: 0px 0px;
          width: 100%;
          .dropdown{
            li{
              a{
                color: #1D316C;
              }
            }
          }
          a{
            padding: 8px 0px;
            display: block;
            color: #1D316C;
            &:before{
              content: none;
            }
          }
        }
      }
    }
  }
`

export default HeaderContainer;