import React from 'react';
import firebase from 'firebase';

const config = {
        apiKey: "AIzaSyAo9fX0OZnKTuiWQ98puHtTn5ItsuorxHI",
        authDomain: "chamartin-cocinaparati.firebaseapp.com",
        databaseURL: "https://chamartin-cocinaparati.firebaseio.com", 
        projectId: "chamartin-cocinaparati",
        storageBucket: "chamartin-cocinaparati.appspot.com",
        messagingSenderId: "961332717860",
        appId: "1:961332717860:web:51a503bd210effa5152bb1",
        measurementId: "G-1H2946S56P"
    };
    
export const useFirebase = ():firebase.app.App => {
    if (!firebase.apps.length) {
        return firebase.initializeApp(config);
    }

    return firebase.apps[0];
}