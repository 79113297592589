import styled from 'styled-components';

const ModalContainer = styled.div`
    
    .modal-open .modal{
        z-index:100000000000000000;
    }

    div.modal-dialog {
        position: fixed !important;
        height: 90vh !important;;
        top:0 !important;;
        left:0 !important;;
        width: 50vw !important;
    }
`

export default ModalContainer;