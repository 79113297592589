import React, { useState, useEffect, Fragment, useReducer } from 'react';
import { navigate } from 'gatsby';
import CookieConsentModal from '../CookieConsentModal';
import CookieBannerContainer from './cookie-banner.style';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import firebase from 'firebase';
import { useFirebase } from '@hooks/UseFirebase';

const COOKIE_CONSENT_COMPONENT_NAME='rcl_consent_given';

let initialCookieValue = Cookies.get(COOKIE_CONSENT_COMPONENT_NAME)? {
  isSet: 1,
} :
{
  isSet: 0,
  accepted: 0,
  statistics: 1
}

const CookieBanner = (props) => {

  const [cookieModalShow, setCookieModalShow] = React.useState(false);  
  const [cookieBannerShow, setCookieBannerShow] = React.useState(true);
  
  const [popupIsOpen, setPopupIsOpen] = useState(!initialCookieValue.isSet)




  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'acceptCurrent':
        //setCookieModalShow(false)
        return {
          ...state,
          isSet: 1,
        }
      case 'declineAll':
        //setCookieModalShow(false)
        return {
          isSet: 1,
          marketing: 0,
        }
      default:
        throw new Error()
    }
  }, initialCookieValue)

  // Update the cookie when state changes
  useEffect(() => {
    if (state.isSet === 1){
      setCookieModalShow(false)
    }
  }, [state])
  
  
  let modalShow = true;

  useEffect(() => {
    let consent = Cookies.get(COOKIE_CONSENT_COMPONENT_NAME)
    if (consent){
      setCookieBannerShow(false);
      setCookieModalShow(false);
    }
  }, [ ]);

  const onCookieSelect = () => {
    //console.log('CookieBanner -- onCookieSelect --- init');
    setCookieModalShow(false);
    setCookieBannerShow(false);
    //dispatch({type: 'acceptCurrent'})
  }

  /*
  const [expand, setExpand] = useState(false);
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () =>
      window.pageYOffset > 50 ? setScroll(true) : setScroll(false)
    );
  }, [ ]);

  style={{ background: "#2B373B" }}
  */
  return(
  <CookieBannerContainer>
    {cookieBannerShow &&
        <>
          <CookieConsent
            enableDeclineButton
            disableStyles={true}
            buttonText="Aceptar"
            declineButtonText="Saber más"
            cookieName="chamartincocinaparati-cookiebanner"
            containerClasses="react-cookie-law-dialog"
            contentClasses="react-cookie-law-msg"
            buttonClasses="react-cookie-law-accept-btn"
            buttonWrapperClasses="react-cookie-law-buttons"
            declineButtonClasses="react-cookie-law-decline-btn"          
            
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            onDecline={() => { 
                  setCookieModalShow(true);
                  // abrimos modal de configuración, página de política de cookies
                  /*
                  Cookies.remove('_ga')
                  Cookies.remove('_gat')
                  Cookies.remove('_gid')
                  */
            }}

            onAccept={() => { 
              //navigate("/cookies")
              //-- expiracion
              var date = new Date();
              date.setTime(date.getTime()+(365*24*60*60*1000));

              Cookies.set('chamartincocinaparati-gdpr-google-analytics',true, {expires: date.getTime()});
              Cookies.set('chamartincocinaparati-gdpr-google-tagmanager',true,  {expires: date.getTime()});
              Cookies.set('chamartincocinaparati-gdpr-facebook-pixel',true,  {expires: date.getTime()});
              const firebaseApp = useFirebase();
              //  console.log('analytics apoyo local', firebaseApp);
              //  console.log('cookies', analytics);
                if (firebaseApp){
              //    console.log('analytics apoyo local -- habilitando analytics');
                  firebase.analytics();
                }                   
              // abrimos modal de configuración, página de política de cookies
              /*
                yolocal-gdpr-google-analytics
                yolocal-gdpr-google-tagmanager
                yolocal-gdpr-facebook-pixel
              */
        }}            
            setDeclineCookie={false}
            hideOnDecline={false}
          >
            Este sitio web almacena cookies en tu ordenador. Estas cookies se utilizan para recopilar información acerca de la forma en que interactúas con nuestro sitio web y recordarlo. Usamos esta información para personalizar y mejorar tu experiencia de navegación y para realizar análisis y recuento de los visitantes, tanto en este sitio web como a través de otros medios. Para obtener más información sobre las cookies que utilizamos, consulta nuestra Política de privacidad.
          </CookieConsent>

          {cookieModalShow &&
          <CookieConsentModal
            size="lg"
            centered
            title="Configuración de cookies" 
            onHide={() => {console.log('CookieBanner --- cookie consent hide'); 
                      setCookieModalShow(false); 
                      //setBannerShow(false);
            }}
            onSelect={() => {
                      //console.log('CookieBanner --- cookie consent onSelect cookie banner option');
                      //setCookieBannerShow(false);
                      //modalShow = false;
                      //console.log('CookieBanner --- cookie consent onSelect --- dispatch acceptCurrent', modalShow);
                      onCookieSelect();
            }}
            show={cookieModalShow}/>
          }
        </>
      }
  </CookieBannerContainer>
  );
}

export default CookieBanner;
